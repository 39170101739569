import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from './Button'; // Assuming Button is a styled component
import Input from './Input'; // Assuming Input is a styled component
import { useNavigate } from 'react-router-dom';

const ConfirmUsername = () => {
  const [username, setUsername] = useState(''); // State to handle the new username
  const [message, setMessage] = useState('');
  const [currentUsername, setCurrentUsername] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the current username from the backend
    const fetchUsername = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-username`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCurrentUsername(response.data.username);
        setUsername(response.data.username); // Pre-fill the current username in the input
      } catch (err) {
        console.error('Failed to fetch username:', err);
      }
    };
    fetchUsername();
  }, []);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value); // Update the state when the input changes
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    if (!username) {
      setError('Username cannot be empty');
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/confirm-username`,
        { username },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    // Handle the success case
    if (response.data.success) {
      setMessage('Username confirmed successfully! You will now be redirected to the home page.');
      setTimeout(() => {
        navigate('/dashboard');
      }, 3000);
    }
  } catch (err) {
    // Check for specific error responses from the backend
    if (err.response && err.response.status === 400) {
      // Handle specific backend error messages
      if (err.response.data.message === 'Username is required') {
        setError('Username cannot be empty');
      } else if (err.response.data.message === 'Username already taken') {
        setError('This username is already taken. Please choose another.');
      } else if (err.response.data.message === 'Username has already been confirmed and cannot be changed.') {
        setError('Your username has already been confirmed and cannot be changed.');
      } else {
        setError(err.response.data.message || 'Failed to confirm username');
      }
    } else {
      // Generic error message for other cases
      setError('Error confirming username. Please try again later.');
    }
    console.error('Error:', err);
  }
};

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900">
      <div className="w-full max-w-md p-8 space-y-6 bg-gray-800 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold text-center text-white">Confirm Your Username</h2>

        <p className="text-center text-white mt-4">
          Your current username is <span className="font-bold">{currentUsername}</span>. Would you like to change it?
        </p>
        <p className="text-center text-gray-400 mb-4">
          Please note, the username you confirm will be permanent and cannot be changed.
        </p>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-white mb-2">New Username</label>
            <Input
              type="text"
              value={username}
              onChange={handleUsernameChange}
              className="w-full px-3 py-2 text-gray-900 bg-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {error && <p className="text-center text-red-500">{error}</p>}

          <Button
            type="submit"
            className="w-full px-4 py-2 font-bold text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Confirm Username
          </Button>
        </form>

        {message && <p className="text-center text-green-500 mt-4">{message}</p>}
      </div>
    </div>
  );
};

export default ConfirmUsername;
