import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from './Button'; // Import the Button component
import Input from './Input'; // Import the Input component
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook


const Register = () => {
  // const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // State for confirm password
  const [message, setMessage] = useState('');
  const [error, setError] = useState(''); // State for handling error messages
  const navigate = useNavigate(); // Initialize the navigate hook


  // Function to clear the message on input change (login form)
  useEffect(() => {
    if (email || password || confirmPassword) {
      setMessage('');
      setError('');
    }
  }, [email, password, confirmPassword]);


  const handleRegister = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/register`, {
        // username,
        email,
        password,
      });
      setMessage('Registration successful! Redirecting to 2FA setup...');


      localStorage.setItem('token', res.data.token); // Store the token with `twoFaSetupCompleted: false`
      localStorage.setItem('twoFaVerified', 'false'); // Set 2FA verification to false
      // localStorage.removeItem('token');
      // localStorage.removeItem('twoFaVerified');

      // Delay the redirect to /2fa-setup for 3 seconds
      setTimeout(() => {
        navigate('/2fa-setup');
      }, 3000); // 3000 milliseconds = 3 seconds
    } catch (err) {
      setMessage('Registration failed');
      if (err.response) {
        console.error('Error response:', err.response.data);
      } else if (err.request) {
        console.error('Error request:', err.request);
      } else {
        console.error('Error:', err.message);
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900">
      <div className="w-full max-w-md p-8 space-y-6 bg-gray-800 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold text-center text-white">Register</h2>
        <form className="space-y-4" onSubmit={handleRegister}>
          {/* <div>
            <label className="block text-sm font-medium text-white">Username</label>
            <Input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-3 py-2 mt-1 text-gray-900 bg-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div> */}
          <div>
            <label className="block text-sm font-medium text-white">Email</label>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 mt-1 text-gray-900 bg-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-white">Password</label>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 mt-1 text-gray-900 bg-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-white">Confirm Password</label>
            <Input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full px-3 py-2 mt-1 text-gray-900 bg-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <Button
            type="submit"
            className="w-full px-4 py-2 font-bold text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Register
          </Button>
        </form>
        {error && <p className="mt-4 text-center text-red-500">{error}</p>}
        {message && <p className="mt-4 text-center text-red-500">{message}</p>}
      </div>
    </div>
  );
};

export default Register;
