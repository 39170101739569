import React from 'react';
import { Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
  const token = localStorage.getItem('token'); // Retrieve the token

  // Decode the token if it exists
  const decodedToken = token ? JSON.parse(atob(token.split('.')[1])) : null;

  // Check if the token is valid and if 2FA has been verified
  const isTwoFaVerified = decodedToken?.twoFaVerified;
  const isTwoFaSetupCompleted = decodedToken?.twoFaSetupCompleted; // Check if 2FA setup was completed


  // If the token exists and 2FA is verified, grant access to protected routes
  if (token && isTwoFaSetupCompleted && isTwoFaVerified) {
    return <Outlet />;
  } 
  // Instead of redirecting, just show a message temporarily.
  else {
    return <div>
      <h2>Token missing or invalid.</h2>
      <p>isTwoFaVerified: {isTwoFaVerified ? 'Yes' : 'No'}</p>
      <p>isTwoFaSetupCompleted: {isTwoFaSetupCompleted ? 'Yes' : 'No'}</p>
      <p>Token: {token ? 'Exists' : 'Does not exist'}</p>
      {/* You can include more details about the token or session here */}
    </div>;
  }
};

export default ProtectedRoute;


