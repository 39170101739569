import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import CreateTrade from './components/CreateTrade';
import Register from './components/Register';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Navbar from './components/Navbar';
import ProtectedRoute from './components/ProtectedRoute';
import Transactions from './components/Transactions'; // Import Transactions component
import TwoFactorSetup from './components/TwoFactorSetup'; // Import the new 2FA setup component
import ErrorPage from './components/ErrorPage'; // Import the ErrorPage component
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import ConfirmUsername from './components/ConfirmUsername'; // Import ConfirmUsername component
import ConfirmEmailReminder from './components/ConfirmEmailReminder'; // Import ConfirmEmailReminder component
import EmailConfirmed from './components/EmailConfirmed'; // Import ConfirmEmailReminder component
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [lastActivity, setLastActivity] = useState(Date.now());
  
  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    window.location.href = '/login'; // Redirect to login after logout
  }, []);  

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    const checkInactivity = () => {
      const now = Date.now();
      const elapsed = now - lastActivity;
      if (elapsed > 5 * 60 * 1000) { // 5 minutes in milliseconds
        handleLogout();
      }
    };

    const intervalId = setInterval(checkInactivity, 1000); // Check inactivity every second

    return () => clearInterval(intervalId);
  }, [lastActivity, isAuthenticated, handleLogout]);

  const handleActivity = () => {
    setLastActivity(Date.now());
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <Navbar isAuthenticated={isAuthenticated} handleLogout={handleLogout} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/2fa-setup" element={<TwoFactorSetup />} /> {/* Add 2FA setup route */}
          <Route path="/confirm-email-reminder" element={<ConfirmEmailReminder />} />
          <Route path="/email-confirmed" element={<EmailConfirmed />} /> {/* New Route */}
          
          {/* Protected routes */}
          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/create-trade" element={<CreateTrade />} />
            <Route path="/transactions" element={<Transactions />} /> {/* Add Transactions route */}
            <Route path="/confirm-username" element={<ConfirmUsername />} /> {/* Add Confirm Username route */}

          {/* New route for the email confirmation reminder */}
          

          </Route>

          {/* Add the error page as the last route */}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
