import React, { useEffect, useState } from 'react';
import api from '../services/api'; // Import the api service
import TickerWithLogo from './TickerWithLogo';

const Dashboard = () => {
  // const [data, setData] = useState(null);
  const [portfolioData, setPortfolioData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  useEffect(() => {
    // Fetch protected data
    const fetchPortfolioData = async () => {
      try {
        const response = await api.get('/api/portfolio');  // Adjust the route as per your setup
        setPortfolioData(response.data.portfolio);
      } catch (error) {
        console.error('Failed to fetch portfolio data:', error);
      }
    };

    fetchPortfolioData();
  }, []);


  const sortData = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sortedData = [...portfolioData].sort((a, b) => {
      if (key === 'averageCostPerShare' || key === 'quantity') {
        const aValue = parseFloat(a[key]) || 0;
        const bValue = parseFloat(b[key]) || 0;
        return direction === 'asc' ? aValue - bValue : bValue - aValue;
      } else if (key === 'cost') {
        const aValue = parseFloat(a.totalNetAmount) || 0;
        const bValue = parseFloat(b.totalNetAmount) || 0;
        return direction === 'asc' ? aValue - bValue : bValue - aValue;
      } else {
        const aValue = a[key]?.toUpperCase() || '';
        const bValue = b[key]?.toUpperCase() || '';
        return direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
    });

    setPortfolioData(sortedData);
  };


  // Function to format the strategy by splitting camelCase and capitalizing each word
  const formatStrategy = (strategy) => {
    if (!strategy) return ''; // Handle empty/null strategies
    
    // Manual mapping for known strategies, otherwise capitalize each word
    const strategyMap = {
      'longCall': 'Long Call',
      'longPut': 'Long Put',
      'cashSecuredPuts': 'Cash Secured Puts',
      'wheeling': 'Wheeling',
      // 'ironCondor': 'Iron Condor',
      // 'straddle': 'Straddle',
      // 'strangle': 'Strangle',
      'coveredCall': 'Covered Call',
      // 'calendarSpread': 'Calendar Spread'
    };
  
    return strategyMap[strategy] || strategy.split(/(?=[A-Z])/).join(' ').replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const formatCurrency = (value) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });

    return formatter.format(value);
  };


  return (
    <div className="min-h-screen bg-gray-900">
      <div className="container mx-auto p-8">
        <h1 className="text-4xl font-bold text-white mb-6">Portfolio</h1>

        {portfolioData.length > 0 ? (
          <table className="min-w-full bg-gray-800 rounded-lg shadow-lg">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left text-gray-400">Logo</th>
                <th
                  className="px-4 py-2 text-left text-gray-400 cursor-pointer"
                  onClick={() => sortData('underlyingSymbol')}
                >
                  Ticker {sortConfig.key === 'underlyingSymbol' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th
                  className="px-4 py-2 text-left text-gray-400 cursor-pointer"
                  onClick={() => sortData('strategy')}
                >
                  Strategy {sortConfig.key === 'strategy' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th
                  className="px-4 py-2 text-left text-gray-400 cursor-pointer"
                  onClick={() => sortData('quantity')}  // Enable sorting for the new "Quantity" column
                >
                  Quantity {sortConfig.key === 'quantity' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th> {/* New Quantity column */}
                <th
                  className="px-4 py-2 text-left text-gray-400 cursor-pointer"
                  onClick={() => sortData('averageCostPerShare')}
                >
                  Breakeven Price {sortConfig.key === 'averageCostPerShare' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th
                  className="px-4 py-2 text-left text-gray-400 cursor-pointer"
                  onClick={() => sortData('cost')}  // Enable sorting for the new "Cost" column
                >
                  Cost {sortConfig.key === 'cost' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th> {/* New Cost column */}
              </tr>
            </thead>
            <tbody>
              {portfolioData.map((item) => (
                <tr key={`${item.underlyingSymbol}-${item.strategy}-${item.strategyNumber}`}>
                  <td className="px-4 py-2">
                    <TickerWithLogo ticker={item.underlyingSymbol} />
                  </td>                  
                  <td className="px-4 py-2 text-white">{item.underlyingSymbol}</td>
                  <td className="px-4 py-2 text-white">
                    {formatStrategy(item.strategy)} {item.strategyNumber}
                  </td>
                  <td className="px-4 py-2 text-white">{item.quantity}</td> {/* Displaying Quantity */}
                  <td className="px-4 py-2 text-white">
                    {isNaN(parseFloat(item.averageCostPerShare)) ? 'N/A' : `${parseFloat(item.averageCostPerShare).toFixed(2)}`}
                  </td>  {/* Displaying Average Cost/Share */}
                  <td className="px-4 py-2 text-white">{formatCurrency(-parseFloat(item.totalNetAmount))}</td>  {/* Displaying Cost */}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-gray-300">No portfolio data available.</p>
        )}
      </div>
    </div>
  );
};

export default Dashboard;



