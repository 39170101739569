import React, { useState, useEffect, useRef } from 'react';
import api from '../services/api';  // Import the API service
import TickerWithLogo from './TickerWithLogo';  // Import the TickerWithLogo component
import axiosInstance from '../services/axiosInstance'; // Adjust the relative path accordingly

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]); // To store the filtered transactions
  const [, setMessage] = useState('');
  const [fromDate, setFromDate] = useState(''); // State for "from" date
  const [toDate, setToDate] = useState(''); // State for "to" date
  const [tickerSearch, setTickerSearch] = useState(''); // State for ticker search
  const [sortConfig, setSortConfig] = useState([]);
  const [page, setPage] = useState(0); // Pagination state
  const itemsPerPage = 50;
  const tableRef = useRef(null);

  // State for strategy and strategy number
  const [strategy, setStrategy] = useState('');
  const [strategyNumber, setStrategyNumber] = useState('');
  const [editableStrategyId, setEditableStrategyId] = useState(null);
  const [editableStrategy, setEditableStrategy] = useState('');
  const [editableStrategyNumber, setEditableStrategyNumber] = useState('');
  const [selectedTransactions, setSelectedTransactions] = useState([]);  


  useEffect(() => {
    const fetchTrades = async () => {
      try {
        const response = await api.get('/trades');
        const transactionsData = response.data.map((transaction) => {

          let typeDisplay = '';
          switch (transaction.instrumentType) {
            case 'VANILLA': typeDisplay = 'Options'; break;
            case 'COMMON_STOCK': typeDisplay = 'Stocks'; break;
            case 'EXCHANGE_TRADED_FUND': typeDisplay = 'ETFs'; break;
            case 'NO_LOAD_TAXABLE': typeDisplay = 'Mutual Funds'; break;
            default: typeDisplay = transaction.instrumentType;
          }

          return {
            ...transaction,
            ticker: transaction.underlyingSymbol,
            typeDisplay,
          };
        });

        setTransactions(transactionsData);
        const sortedFilteredTransactions = applyMultiSorting(transactionsData, sortConfig);
        setFilteredTransactions(sortedFilteredTransactions);
      } catch (error) {
        setMessage('Failed to fetch transactions.');
      }
    };

    fetchTrades();
  }, [sortConfig]);

const applyMultiSorting = (data, sortConfig) => {
  if (!sortConfig || !sortConfig.length) return data; // Return data as is if there's no sort config

  let sortedResult = [...data]; // Start with the original data

  // Loop through each config in sortConfig and sort the data accordingly
  for (let i = 0; i < sortConfig.length; i++) {
    const { key, direction } = sortConfig[i];

    sortedResult = sortedResult.sort((a, b) => {
      let comparison = 0;

      // Special handling for numeric fields
      if (key === 'fees_comm') {
        const aValue = Math.ceil((a.cost - a.netAmount) * 100) / 100;
        const bValue = Math.ceil((b.cost - b.netAmount) * 100) / 100;
        comparison = aValue - bValue;
      } else if (key === 'date') {
        const aValue = new Date(a.tradeDate);
        const bValue = new Date(b.tradeDate);
        comparison = aValue - bValue;
      } else if (key === 'quantity') {
        const aValue = parseFloat(a.amount);
        const bValue = parseFloat(b.amount);
        comparison = aValue - bValue;
      } else if (key === 'amount') {
        const aValue = parseFloat(a.netAmount);
        const bValue = parseFloat(b.netAmount);
        comparison = aValue - bValue;
    } else if (key === 'positionEffect') {
      // Ensure empty or null positionEffect values always appear at the end
      const aPositionEffect = a.positionEffect !== null && a.positionEffect !== undefined && a.positionEffect !== '' ? a.positionEffect.toUpperCase() : null;
      const bPositionEffect = b.positionEffect !== null && b.positionEffect !== undefined && b.positionEffect !== '' ? b.positionEffect.toUpperCase() : null;

      if (aPositionEffect === null) return 1; // Push 'a' to the end if it's empty
      if (bPositionEffect === null) return -1; // Push 'b' to the end if it's empty

      comparison = aPositionEffect.localeCompare(bPositionEffect);
      } else if (key === 'strategy') {

        // Handle sorting for strategy + strategyNumber together
        const aStrategy = a.strategy ? a.strategy.toLowerCase() : null;
        const bStrategy = b.strategy ? b.strategy.toLowerCase() : null;

        const aStrategyNumber = a.strategyNumber ? parseInt(a.strategyNumber) : Number.MAX_SAFE_INTEGER;
        const bStrategyNumber = b.strategyNumber ? parseInt(b.strategyNumber) : Number.MAX_SAFE_INTEGER;

        // Ensure empty or null strategy values always appear at the end
        if (aStrategy === null) return 1; // Push 'a' to the end if it's empty
        if (bStrategy === null) return -1; // Push 'b' to the end if it's empty

        // First sort by strategy name
        comparison = aStrategy.localeCompare(bStrategy);
        
        // If strategy names are equal, sort by strategyNumber
        if (comparison === 0) {
          comparison = aStrategyNumber - bStrategyNumber;
        }
      } else if (key === 'strikePrice') {
        // Ensure empty or null strikePrice values always appear at the end
        const aStrike = a.strikePrice !== null && a.strikePrice !== undefined ? parseFloat(a.strikePrice) : Number.MAX_SAFE_INTEGER;
        const bStrike = b.strikePrice !== null && b.strikePrice !== undefined ? parseFloat(b.strikePrice) : Number.MAX_SAFE_INTEGER;

        if (a.strikePrice === null || a.strikePrice === undefined) return 1; // Push 'a' to the end if it's empty
        if (b.strikePrice === null || b.strikePrice === undefined) return -1; // Push 'b' to the end if it's empty

        comparison = aStrike - bStrike;

      } else if (key === 'putCall') {
        // Ensure empty or null putCall values always appear at the end
        const aPutCall = a.putCall !== null && a.putCall !== undefined && a.putCall !== '' ? a.putCall.toUpperCase() : null;
        const bPutCall = b.putCall !== null && b.putCall !== undefined && b.putCall !== '' ? b.putCall.toUpperCase() : null;

        if (aPutCall === null) return 1; // Push 'a' to the end if it's empty
        if (bPutCall === null) return -1; // Push 'b' to the end if it's empty

        comparison = aPutCall.localeCompare(bPutCall);

      }  else if (typeof a[key] === 'string' && typeof b[key] === 'string') {
        comparison = a[key].localeCompare(b[key]);
      } else if (typeof a[key] === 'number' && typeof b[key] === 'number') {
        comparison = a[key] - b[key];
      } else if (a[key] instanceof Date && b[key] instanceof Date) {
        comparison = a[key] - b[key];
      } else {
        comparison = a[key] > b[key] ? 1 : -1;
      }

      return direction === 'asc' ? comparison : -comparison;
    });

  }
  return sortedResult;
};





const handlePagination = (setPage, currentPage, dataLength, tableRef) => {
  // Ensure we don't paginate beyond the available data
  const maxPage = Math.ceil(dataLength / itemsPerPage);

  if (currentPage + 1 < maxPage) { // Ensure next page exists
    if (tableRef?.current) {
      const scrollPosition = tableRef.current.scrollTop;
      setPage(currentPage + 1);
      setTimeout(() => {
        if (tableRef.current) {
          tableRef.current.scrollTop = scrollPosition;
        }
      }, 0);
    } else {
      setPage(currentPage + 1);
    }
  }
};

const handlePrevPagination = (setPage, currentPage, tableRef) => {
  if (currentPage > 0) {
    if (tableRef?.current) {
      const scrollPosition = tableRef.current.scrollTop;
      setPage(currentPage - 1);
      setTimeout(() => {
        if (tableRef.current) {
          tableRef.current.scrollTop = scrollPosition;
        }
      }, 0);
    } else {
      setPage(currentPage - 1);
    }
  }
};

const sortTransactions = (key) => {
  let newSortConfig = Array.isArray(sortConfig) ? [...sortConfig] : [];

  // Step 1: Check if the key already exists in the sort configuration
  const existingSortIndex = newSortConfig.findIndex(config => config.key === key);

  if (existingSortIndex !== -1) {
    // Step 4: Remove the existing key and add it to the end with the toggled direction
    const existingSort = newSortConfig.splice(existingSortIndex, 1)[0];
    existingSort.direction = existingSort.direction === 'asc' ? 'desc' : 'asc';
    newSortConfig.push(existingSort);
  } else {
    // Step 2 and 3: Add new key to the sortConfig
    newSortConfig.push({ key, direction: 'asc' });
  }

  setSortConfig(newSortConfig);
  const sortedTransactions = applyMultiSorting(filteredTransactions.length ? filteredTransactions : transactions, newSortConfig);
  setFilteredTransactions(sortedTransactions);
};

  const handleCheckBoxChange = (transaction) => {
  // Check if the transaction is already selected
  let updatedSelectedTransactions = [...selectedTransactions];  // Create a copy of selectedTransactions
  
  if (updatedSelectedTransactions.some(t => t.id === transaction.id)) {
    updatedSelectedTransactions = updatedSelectedTransactions.filter(t => t.id !== transaction.id);
  } else {
    updatedSelectedTransactions.push(transaction);
  }

    // Update selectedTransactions state
    setSelectedTransactions(updatedSelectedTransactions);

  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      try {
        const jsonContent = JSON.parse(e.target.result);
        const uploadedTransactions = jsonContent.BrokerageTransactions || [];

        if (!uploadedTransactions || !Array.isArray(uploadedTransactions)) {
          setMessage('Invalid data format.');
          return;
        }

        // Prepare the data to be sent to the backend
        const trades = uploadedTransactions.map((transaction) => {
          let dateValue = null;
          if (transaction.Date && transaction.Date.includes(' as of ')) {
            dateValue = new Date(transaction.Date.split(' as of ')[0].trim());
          } else if (transaction.Date) {
            dateValue = new Date(transaction.Date);
          } else {
            dateValue = new Date();  // Default to the current date if missing
          }

          return {
            ticker: transaction.Symbol ? transaction.Symbol.split(' ')[0] : '',
            type: transaction.Action || '',
            quantity: transaction.Quantity ? parseInt(transaction.Quantity) : 0,
            price: transaction.Price ? parseFloat(transaction.Price.replace(/[$,]/g, '')) : 0,
            date: dateValue.toISOString(),
            description: transaction.Description || '',
            fees_comm: transaction["Fees & Comm"] ? parseFloat(transaction["Fees & Comm"].replace(/[$,]/g, '')) : 0,
            amount: transaction.Amount ? parseFloat(transaction.Amount.replace(/[$,]/g, '')) : 0,
            source: 'Manually upload',
            isOpen: false, // Default to Closed
          };
        });

        const response = await api.post('/trades/upload', { trades }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response.status === 201) {
          setMessage('Transactions uploaded successfully!');
          const updatedTrades = await api.get('/trades');
          setTransactions(updatedTrades.data);
          setFilteredTransactions(updatedTrades.data); // Update filtered transactions as well
        } else {
          setMessage('Failed to upload transactions.');
        }
      } catch (error) {
        console.error('Error processing file:', error.response ? error.response.data : error.message);
        setMessage('Failed to process the file.');
      }
    };

    reader.readAsText(file);
  };

  const handleSearchClick = () => {
    // If either date is not set, return early without filtering
    if (!fromDate || !toDate) {
      return;
    }

    const from = fromDate.split('T')[0];
    const to = toDate.split('T')[0];

    let filtered = transactions.filter(transaction => {
      const transactionDate = new Date(transaction.tradeDate);
      const localDateString = transactionDate.toLocaleDateString('en-CA'); // 'en-CA' gives 'YYYY-MM-DD' format
      return localDateString >= from && localDateString <= to;
    });

    // Apply ticker search filter
    if (tickerSearch) {
      filtered = filtered.filter(transaction => 
        transaction.ticker.toUpperCase().includes(tickerSearch)
      );
    }

    const sortedFilteredTransactions = applyMultiSorting(filtered, sortConfig);
    setFilteredTransactions(sortedFilteredTransactions);
  };

  const handleResetClick = () => {
    setFromDate('');
    setToDate('');
  
    // Restore full transactions without any date filter
    let filtered = transactions;
  
    // Apply ticker search filter if any
    if (tickerSearch) {
      filtered = filtered.filter(transaction => 
        transaction.ticker.toUpperCase().includes(tickerSearch)
      );
    }
  
    // Reapply sorting to the full list of transactions
    const sortedFilteredTransactions = applyMultiSorting(filtered, sortConfig);
    setFilteredTransactions(sortedFilteredTransactions);
    setPage(0);
  };
  

  const handleTickerSearchChange = (e) => {
    const searchValue = e.target.value.toUpperCase();
    setTickerSearch(searchValue);

    // Filter the transactions based on the ticker search
    let filtered = transactions.filter(transaction =>
      transaction.ticker.toUpperCase().includes(searchValue)
    );

    if (fromDate && toDate) {
      const from = fromDate.split('T')[0];
      const to = toDate.split('T')[0];
      filtered = filtered.filter(transaction => {
        const transactionDate = new Date(transaction.tradeDate).toLocaleDateString('en-CA');
        return transactionDate >= from && transactionDate <= to;
      });
    }


    const sortedFilteredTransactions = applyMultiSorting(filtered, sortConfig);

    // Reset to the first page when a ticker search is applied
    setPage(0);
    setFilteredTransactions(sortedFilteredTransactions);
  };


  // Function to handle Sync Schwab Data
  const handleSyncSchwabData = () => {
    // Redirect to the OAuth route to initiate Schwab OAuth process
    window.location.href = `${process.env.REACT_APP_API_URL}/auth`;
  };

// Function to handle including transactions in the strategy
const handleIncludeTransactions = async () => {
  try {
    // Perform validation checks
    if (selectedTransactions.length === 0) {
      alert('Please select at least one transaction to update.');
      return;
    }

    // Check if strategy and strategyNumber are filled
    if (!strategy || !strategyNumber) {
      alert('Please provide both a strategy and strategy number.');
      return;
    }

    // Prepare the list of transaction IDs to update
    const transactionIds = selectedTransactions.map(trade => trade.id);

    // Send the request to the backend to update transactions
    const response = await api.post('/trades/assign-strategy', {
      strategy,
      strategyNumber,
      transactionIds
    });

    // Check for validation errors from the server
    if (response.data.errors) {
      response.data.errors.forEach(error => alert(error));
    } else {
      alert(response.data.message);  // <-- This displays the message on the frontend
      
      // Update frontend state with new strategies for selected transactions
      const updatedTransactions = transactions.map(trade => {
        if (transactionIds.includes(trade.id)) {
          return { 
            ...trade, 
            strategy: strategy, 
            strategyNumber: strategyNumber 
          };
        }
        return trade;
      });

      setTransactions(updatedTransactions);  // Update the global transactions state

      // Preserve the current filters (date and ticker search)
      let filteredTransactions = updatedTransactions;

      if (fromDate && toDate) {
        // Apply the date filter
        filteredTransactions = filteredTransactions.filter((transaction) => {
          const tradeDate = new Date(transaction.tradeDate);
          return tradeDate >= new Date(fromDate) && tradeDate <= new Date(toDate);
        });
      }

      if (tickerSearch) {
        // Apply the ticker search filter
        filteredTransactions = filteredTransactions.filter((transaction) =>
          transaction.ticker.toUpperCase().includes(tickerSearch.toUpperCase())
        );
      }

      // **Reapply sorting to the filtered transactions using the current sortConfig**
      const sortedFilteredTransactions = applyMultiSorting(filteredTransactions, sortConfig);
      setFilteredTransactions(sortedFilteredTransactions); // Update filtered transactions as well

      // Clear selected transactions after update
      setSelectedTransactions([]);  // Unselect the checkboxes after the update
    }

  } catch (error) {
    console.error('Failed to include transactions in strategy:', error);
    alert('An error occurred while including transactions in strategy.');
  }
};


// Handler to start editing
const handleDoubleClick = (transaction) => {
  setEditableStrategyId(transaction.id);
  setEditableStrategy(transaction.strategy || '');
  setEditableStrategyNumber(transaction.strategyNumber || '');
};

// Handler for saving the edited strategy
const handleStrategySave = async (transaction) => {
  try {
    const updatedStrategy = editableStrategy.trim() === '' ? null : editableStrategy;
    const updatedStrategyNumber = editableStrategyNumber.trim() === '' ? null : editableStrategyNumber;

    // Validation
    if (updatedStrategy && !updatedStrategyNumber) {
      alert('A strategy number is required when selecting a strategy.');
      return;
    }

    if (!updatedStrategy && updatedStrategyNumber) {
      alert('A strategy is required when specifying a strategy number.');
      return;
    }

    // Save to the backend
    await api.post('/trades/update-strategy', {
      transactionId: transaction.id,
      strategy: updatedStrategy,
      strategyNumber: updatedStrategyNumber
    });

    // Update the frontend state for closedTrades only
    const updatedTransactions = transactions.map((trade) =>
      trade.id === transaction.id ? { ...trade, strategy: updatedStrategy, strategyNumber: updatedStrategyNumber } : trade
    );
    setTransactions(updatedTransactions);
    setEditableStrategyId(null);

    // Reapply the current sort after updating the strategy
    const sortedUpdatedTransactions = applyMultiSorting(updatedTransactions, sortConfig);
    setFilteredTransactions(sortedUpdatedTransactions);
  } catch (error) {
    alert('An error occurred while updating the strategy.');
  }
};


const capitalizeFirstLetter = (str) => {
  return str
    ? str
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert spaces between camelCase words
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter
        .join(' ')
    : '';
};


const handleRemoveStrategy = async () => {
  try {
    // Check if there are any selected transactions
    if (selectedTransactions.length === 0) {
      alert('No transactions selected.');
      return;
    }

    // Prepare the list of transaction IDs to update
    const transactionIds = selectedTransactions.map(trade => trade.id);

    // Send the request to the backend to remove the strategy
    const response = await api.post('/trades/remove-strategy', { transactionIds });

    // Check for validation errors from the server
    if (response.data.errors) {
      response.data.errors.forEach(error => alert(error));
    } else {
      alert('Strategy successfully removed from selected transactions!');

      // Update frontend state with the strategy removed
      const updatedTransactions = transactions.map(trade => {
        if (transactionIds.includes(trade.id)) {
          return { 
            ...trade, 
            strategy: null, 
            strategyNumber: null 
          };
        }
        return trade;
      });

      setTransactions(updatedTransactions);  // Update the global transactions state

      // Preserve the current filters (date and ticker search)
      let filteredTransactions = updatedTransactions;

      if (fromDate && toDate) {
        // Apply the date filter
        filteredTransactions = filteredTransactions.filter((transaction) => {
          const tradeDate = new Date(transaction.tradeDate);
          return tradeDate >= new Date(fromDate) && tradeDate <= new Date(toDate);
        });
      }

      if (tickerSearch) {
        // Apply the ticker search filter
        filteredTransactions = filteredTransactions.filter((transaction) =>
          transaction.ticker.toUpperCase().includes(tickerSearch.toUpperCase())
        );
      }

      // Reapply sorting to the filtered transactions using the current sortConfig
      const sortedFilteredTransactions = applyMultiSorting(filteredTransactions, sortConfig);
      setFilteredTransactions(sortedFilteredTransactions);

      // Clear selected transactions after removing the strategy
      setSelectedTransactions([]);
    }

  } catch (error) {
    console.error('Failed to remove strategy:', error);
    alert('An error occurred while removing the strategy.');
  }
};


const handleRefreshTransactions = async () => {
  try {
    const response = await axiosInstance.post('/schwab/refresh');
    const newTransactions = response.data;

    // Update the transactions in the frontend
    setTransactions((prevTransactions) => [...prevTransactions, ...newTransactions]);

    // Notify the user about the success
    // alert('Transactions successfully refreshed!');
    setMessage('Transactions successfully refreshed!');
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // alert("Schwab tokens expired. Please re-authenticate with Schwab.");
      setMessage("Schwab tokens expired. Please re-authenticate with Schwab.");
    } else {
      console.error("Error refreshing transactions:", error);
      setMessage("Failed to refresh transactions.");
    }
  }
};



  const renderTable = (title, data, sortFunction, tableRef, page, setPage) => {
    const visibleData = data.slice(page * itemsPerPage, (page + 1) * itemsPerPage);
    const placeholderCount = visibleData.length > 0 ? itemsPerPage - visibleData.length : itemsPerPage - 1;
    const placeholders = Array(placeholderCount).fill(null);

    return (
    <div ref={tableRef} className="table-container overflow-y-auto mt-8">

        <>
        <table className="min-w-full bg-gray-800 rounded-lg shadow-lg overflow-hidden" >
          <thead className="bg-gray-700 text-gray-400 text-sm uppercase tracking-wider">
            <tr>
            <th className="p-4 text-left">Select</th>
            <th className="p-4 text-left">Logo</th>
            <th
                className="p-4 text-left cursor-pointer"
                onClick={() => sortFunction('ticker')}
            >
                Ticker {sortConfig && sortConfig.find(config => config.key === 'ticker') ? 
                (sortConfig.find(config => config.key === 'ticker').direction === 'asc' ? '↑' : '↓') : ''}
            </th>
            <th
                className="p-4 text-left cursor-pointer"
                onClick={() => sortFunction('date')}
            >
                Date {sortConfig && sortConfig.find(config => config.key === 'date') ? 
                (sortConfig.find(config => config.key === 'date').direction === 'asc' ? '↑' : '↓') : ''}
            </th>
            <th
                className="p-4 text-left cursor-pointer"
                onClick={() => sortFunction('typeDisplay')}
            >
                Type {sortConfig && sortConfig.find(config => config.key === 'typeDisplay') ? 
                (sortConfig.find(config => config.key === 'typeDisplay').direction === 'asc' ? '↑' : '↓') : ''}
            </th>
            <th
                className="p-4 text-left cursor-pointer"
                onClick={() => sortFunction('strikePrice')}
            >
                Strike {sortConfig && sortConfig.find(config => config.key === 'strikePrice') ? 
                (sortConfig.find(config => config.key === 'strikePrice').direction === 'asc' ? '↑' : '↓') : ''}
            </th>
            <th
                className="p-4 text-left cursor-pointer"
                onClick={() => sortFunction('putCall')}
            >
                Put/Call {sortConfig && sortConfig.find(config => config.key === 'putCall') ? 
                (sortConfig.find(config => config.key === 'putCall').direction === 'asc' ? '↑' : '↓') : ''}
            </th>
            <th
                className="p-4 text-left cursor-pointer"
                onClick={() => sortFunction('positionEffect')}
            >
                Open/Close {sortConfig && sortConfig.find(config => config.key === 'positionEffect') ? 
                (sortConfig.find(config => config.key === 'positionEffect').direction === 'asc' ? '↑' : '↓') : ''}
            </th>
            <th
                className="p-4 text-left cursor-pointer"
                onClick={() => sortFunction('quantity')}
            >
                Quantity {sortConfig && sortConfig.find(config => config.key === 'quantity') ? 
                (sortConfig.find(config => config.key === 'quantity').direction === 'asc' ? '↑' : '↓') : ''}
            </th>
            <th
                className="p-4 text-left cursor-pointer"
                onClick={() => sortFunction('price')}
            >
                Price {sortConfig && sortConfig.find(config => config.key === 'price') ? 
                (sortConfig.find(config => config.key === 'price').direction === 'asc' ? '↑' : '↓') : ''}
            </th>
            <th
                className="p-4 text-left cursor-pointer"
                onClick={() => sortFunction('fees_comm')}
            >
                Fees {sortConfig && sortConfig.find(config => config.key === 'fees_comm') ? 
                (sortConfig.find(config => config.key === 'fees_comm').direction === 'asc' ? '↑' : '↓') : ''}
            </th>
            <th
                className="p-4 text-left cursor-pointer"
                onClick={() => sortFunction('amount')}
            >
                Amount {sortConfig && sortConfig.find(config => config.key === 'amount') ? 
                (sortConfig.find(config => config.key === 'amount').direction === 'asc' ? '↑' : '↓') : ''}
            </th>
            <th
                className="p-4 text-left cursor-pointer"
                onClick={() => sortFunction('strategy')}
            >
                Strategy {sortConfig && sortConfig.find(config => config.key === 'strategy') ? 
                (sortConfig.find(config => config.key === 'strategy').direction === 'asc' ? '↑' : '↓') : ''}
            </th>
            </tr>
          </thead>
          <tbody className="bg-gray-900 text-gray-300">
            {/* Render actual data rows */}
            {visibleData.length > 0 ? (
              visibleData.map((transaction) => (
                <tr key={transaction.id} className="hover:bg-gray-700">
                  <td className="p-4 text-center">
                    <div className="flex justify-center items-center">
                    <input
                      type="checkbox"
                      id={`checkbox-${transaction.id}`} // Unique ID for each checkbox
                      className="modern-checkbox"
                      checked={selectedTransactions.some(t => t.id === transaction.id)}  // Check if the transaction is selected
                      onChange={() => handleCheckBoxChange(transaction)}
                    />
                    <label htmlFor={`checkbox-${transaction.id}`} className="checkbox-label"></label>
                    </div>
                  </td>
                  <td className="p-4">
                    <TickerWithLogo ticker={transaction.ticker} />
                  </td>
                  <td className="p-4">{transaction.ticker}</td>
                  <td className="p-4">{new Date(transaction.tradeDate).toLocaleDateString()}</td>
                  <td className="p-4">{transaction.typeDisplay}</td>
                  <td className="p-4">{transaction.strikePrice}</td>
                  <td className="p-4">{transaction.putCall}</td>
                  <td className="p-4">{transaction.positionEffect}</td>
                  <td className="p-4">{transaction.amount}</td>
                  <td className="p-4">{transaction.price ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(transaction.price.toFixed(2)) : 'N/A'}</td>
                  <td className="p-4">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Math.ceil((transaction.cost - transaction.netAmount) * 100) / 100)}</td>
                  <td className="p-4">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(transaction.netAmount)}</td>
                  <td
                    className="p-4 text-left cursor-pointer"
                    onDoubleClick={() => handleDoubleClick(transaction)}
                  >
                    {editableStrategyId === transaction.id ? (
                      <div>
                        {/* Input for Strategy */}
                        <select
                          value={editableStrategy}
                          onChange={(e) => setEditableStrategy(e.target.value)}
                          className="p-2 bg-gray-700 border border-gray-600 rounded-lg shadow-sm text-gray-300 focus:ring-blue-500 focus:border-blue-500"
                        >
                          <option value="">Select Strategy</option>
                          <option value="longCall">Long Call</option>
                          <option value="longPut">Long Put</option>
                          {/* <option value="ironCondor">Iron Condor</option> */}
                          <option value="coveredCall">Covered Call</option>
                          {/* <option value="straddle">Straddle</option>
                          <option value="strangle">Strangle</option> */}
                          <option value="wheeling">Wheeling</option>
                          <option value="cashSecuredPut">Cash Secured Put</option>
                          {/* <option value="calendarSpread">Calendar Spread</option> */}
                        </select>
                        {/* Input for Strategy Number */}
                        <input
                          type="text"
                          value={editableStrategyNumber}
                          onChange={(e) => setEditableStrategyNumber(e.target.value)}
                          className="ml-2 p-2 bg-gray-700 border border-gray-600 rounded-lg shadow-sm text-gray-300 focus:ring-blue-500 focus:border-blue-500 w-12 h-10"  // w-16 controls width, h-10 ensures height is same as dropdown
                          placeholder="#"
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleStrategySave(transaction);  // Pass tableType (either 'openTrades' or 'closedTrades')
                            }
                          }}
                        />
                      </div>
                    ) : (
                      // Render "N/A" if both strategy and strategyNumber are missing
                      (transaction.strategy ? capitalizeFirstLetter(transaction.strategy) : 'N/A') +
                      (transaction.strategyNumber ? ` ${transaction.strategyNumber}` : '')
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="p-4 text-center text-gray-400" style={{ height: '83px' }}>No transactions to display.</td>
              </tr>
            )}

              {/* Render placeholder rows to maintain table height */}
              {placeholders.map((_, index) => (
                <tr key={`placeholder-${index}`} className="hover:bg-gray-700">
                <td className="p-4 text-center">
                  <div className="flex justify-center items-center">
                      {/* Use an invisible image or similar div to maintain height */}
                      <div className="logo-placeholder" style={{ width: '50px', height: '50px', visibility: 'hidden' }}>
                        <img src="" alt="" />
                      </div>
                  </div>
                </td>
                <td className="p-4">{/* Empty placeholder */}</td>
                <td className="p-4">{/* Empty placeholder */}</td>
                <td className="p-4">{/* Empty placeholder */}</td>
                {/* Add other empty cells here to match the number of columns */}
                <td className="p-4" colSpan="9"></td> {/* Adjust colSpan as needed */}
              </tr>
              ))}

          </tbody>
        </table>


        {/* Pagination buttons */}
        <div className="flex justify-between mt-4">
        <button
              className="bg-gray-700 text-gray-400 py-2 px-4 rounded-lg hover:bg-gray-600 pagination-button"
              onClick={() => handlePrevPagination(setPage, page, tableRef)}
              disabled={page === 0}
            >
              Previous
            </button>
            <button
              className="bg-gray-700 text-gray-400 py-2 px-4 rounded-lg hover:bg-gray-600 pagination-button"
              onClick={() => handlePagination(setPage, page, data.length, tableRef)}
              disabled={(page + 1) * itemsPerPage >= data.length}
            >
              Next
            </button>
          </div>
          </>

    </div>
  );
};


return (
  <div className="min-h-screen bg-gray-900">
    <div className="container mx-auto p-8">
      <h1 className="text-4xl font-bold text-white mb-6">Transactions</h1>
      
      {/* Flexbox to split the filters and options strategy section */}
      <div className="flex space-x-8 justify-between mb-8">

        {/* Filter Section (takes up 50%) */}
        <div className="w-1/2 bg-gray-800 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold text-white mb-6">Filters</h2>

          <div className="flex flex-wrap gap-4 mb-4">
            {/* Date range filter */}
            <div className="flex items-center space-x-2">
              <label htmlFor="fromDate" className="text-sm font-medium text-gray-300">From:</label>
              <input
                type="date"
                id="fromDate"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                className="p-2 bg-gray-700 border border-gray-600 rounded-lg shadow-sm text-gray-300 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="flex items-center space-x-2">
              <label htmlFor="toDate" className="text-sm font-medium text-gray-300">To:</label>
              <input
                type="date"
                id="toDate"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                className="p-2 bg-gray-700 border border-gray-600 rounded-lg shadow-sm text-gray-300 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <button
              onClick={handleSearchClick}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-500 transition ease-in-out duration-150"
            >
              Search
            </button>
            <button
              onClick={handleResetClick}
              className="bg-red-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-red-500 transition ease-in-out duration-150"
            >
              Reset
            </button>
          </div>

          {/* Ticker search filter */}
          <div className="flex flex-wrap gap-4 mb-4">
            <div className="flex items-center space-x-2">
              <label htmlFor="tickerSearch" className="text-sm font-medium text-gray-300">Search Ticker:</label>
              <input
                type="text"
                id="tickerSearch"
                value={tickerSearch}
                onChange={handleTickerSearchChange}
                className="p-2 w-64 bg-gray-700 border border-gray-600 rounded-lg shadow-sm text-gray-300 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter Ticker Symbol"
              />
            </div>
          </div>
        </div> {/* Closing Filter Section */}

        {/* Options Strategy Section (takes up 50%) */}
        <div className="w-1/2 bg-gray-800 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold text-white mb-6">Update Transactions for Options Strategy</h2>

          {/* Strategy dropdown */}
          <div className="flex flex-wrap gap-4 mb-4">
   
            <label htmlFor="strategy" className="block text-sm font-medium text-gray-300">Strategy:</label>
            <select
              id="strategy"
              value={strategy}
              onChange={(e) => setStrategy(e.target.value)}
              className="p-2 w-full bg-gray-700 border border-gray-600 rounded-lg shadow-sm text-gray-300 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Select Strategy</option>
              <option value="longCall">Long Call</option>
              <option value="longPut">Long Put</option>
              <option value="ironCondor">Iron Condor</option>
              <option value="coveredCall">Covered Call</option>
              <option value="straddle">Straddle</option>
              <option value="strangle">Strangle</option>
              <option value="wheeling">Wheeling</option>
              <option value="cashSecuredPut">Cash Secured Put</option>
              <option value="calendarSpread">Calendar Spread</option>
            </select>

          </div>

          {/* Strategy number input */}
          <div className="flex flex-wrap gap-4 mb-4">
  
            <label htmlFor="strategyNumber" className="block text-sm font-medium text-gray-300">Strategy Number - Assign a unique number to differentiate between identical strategies for the same ticker:</label>
            <input
              type="text"
              id="strategyNumber"
              value={strategyNumber}
              onChange={(e) => setStrategyNumber(e.target.value)}
              className="p-2 w-full bg-gray-700 border border-gray-600 rounded-lg shadow-sm text-gray-300 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter Strategy Number"
            />

          </div>

          {/* Button to include strategy */}
          <div className="flex space-x-4">
          <button
            onClick={handleIncludeTransactions}
            className="bg-green-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-green-500 transition ease-in-out duration-150"
          >
            Update Transactions
          </button>
          <button
            onClick={handleRemoveStrategy}
            className="bg-red-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-red-500 transition ease-in-out duration-150"
          >
            Remove Strategy
          </button>
          </div>
        </div> {/* Closing Options Strategy Section */}

      </div> {/* Closing Flexbox for Filter and Strategy Section */}

      {/* Buttons for Schwab Sync and Upload */}
      <div className="mt-8 flex space-x-4">
        <button
          onClick={handleSyncSchwabData}
          className="px-6 py-3 bg-blue-600 text-white font-bold rounded-lg shadow-md hover:bg-blue-700 transition duration-200"
        >
          Sync Schwab Live Data
        </button>

        <button
          onClick={handleRefreshTransactions}
          className="px-6 py-3 bg-green-600 text-white font-bold rounded-lg shadow-md hover:bg-green-700 transition duration-200"
        >
          Refresh
        </button>

        <label
          className="px-6 py-3 bg-green-600 text-white font-bold rounded-lg shadow-md hover:bg-green-700 transition duration-200 cursor-pointer"
        >
          Upload Schwab Data (JSON)
          <input
            type="file"
            accept=".json"
            onChange={handleFileUpload}
            className="hidden"
          />
        </label>
      </div>

      {/* Open and Closed Trades Tables with Pagination */}
      <div className="transactions-container">
        <div className="closed-trades">
          {renderTable(
            "Transactions",
            filteredTransactions,
            sortTransactions,
            tableRef,
            page,
            setPage,
          )}
        </div>
      </div>
    </div>
  </div>
);



};
export default Transactions;

