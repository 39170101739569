import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Navbar = ({ isAuthenticated, handleLogout }) => {
  const location = useLocation();
  const navigate = useNavigate();

  // Retrieve and decode the token from localStorage
  const token = localStorage.getItem('token');
  let decodedToken = null;
  
  if (token) {
    try {
      const parts = token.split('.');
      if (parts.length === 3) {
        decodedToken = JSON.parse(atob(parts[1]));
      } else {
        console.error('Invalid JWT format');
      }
    } catch (err) {
      console.error('Failed to decode token:', err);
      localStorage.removeItem('token'); // Clear the invalid token if decoding fails
    }
  }

  // Check if 2FA is completed
  const isTwoFaVerified = decodedToken?.twoFaVerified === true;
  const isTwoFaSetupCompleted = decodedToken?.twoFaSetupCompleted === true;

  const pathExists = (path) => {
    const validPaths = ['/', '/login', '/register', '/dashboard', '/create-trade', '/transactions', '/2fa-setup', '/forgot-password', '/reset-password/:token', '/confirm-username', '/confirm-email-reminder']; // Add all valid paths here
    return validPaths.includes(path);
  };
  

  // Handle navigation with custom logic
  const handleNavigation = (e, path) => {

    // If the user is on the 2FA setup page and tries to navigate away, prevent navigation
    if (location.pathname === '/2fa-setup' && (!isTwoFaSetupCompleted || !isAuthenticated)) {
      e.preventDefault();
      alert('You must complete 2FA setup to use the website-2.');
      navigate('/2fa-setup');
    } else if (location.pathname === '/2fa-setup' && !pathExists(path)) {
      // If the user tries to navigate to a non-existent page from /2fa-setup, stay on /2fa-setup
      e.preventDefault();
      alert('This page does not exist. Please complete your 2FA setup.');
      navigate('/2fa-setup');
    } else if (!isTwoFaVerified && isAuthenticated && isTwoFaSetupCompleted) {
      // If 2FA is not verified during login, block navigation to protected routes
      e.preventDefault();
      alert('You must complete 2FA verification to access this page-3.');
      navigate('/login');
    } else {
      // Allow navigation for valid routes
      navigate(path);
    }
  };
  

  const isAuthPage = location.pathname === '/login' || location.pathname === '/2fa-setup' || location.pathname === '/register';

  return (
    <nav className="bg-gray-800 p-4">
      <ul className="flex space-x-4">
        <li>
          <a href="/" onClick={(e) => handleNavigation(e, '/')}>
            Home
          </a>
        </li>
        {isAuthenticated && isTwoFaSetupCompleted && isTwoFaVerified && !isAuthPage ? (
          <>
            <li>
              <a href="/dashboard" onClick={(e) => handleNavigation(e, '/dashboard')}>
                Dashboard
              </a>
            </li>
            <li>
              <a href="/create-trade" onClick={(e) => handleNavigation(e, '/create-trade')}>
                Create Trade
              </a>
            </li>
            <li>
              <a href="/transactions" onClick={(e) => handleNavigation(e, '/transactions')}>
                Transactions
              </a>
            </li>
            <li>
              <button onClick={handleLogout} className="text-white">
                Logout
              </button>
            </li>
          </>
        ) : (
          <>
            <li>
              <a href="/login" onClick={(e) => handleNavigation(e, '/login')}>
                Login
              </a>
            </li>
            <li>
              <a href="/register" onClick={(e) => handleNavigation(e, '/register')}>
                Register
              </a>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
