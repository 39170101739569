// EmailConfirmed.js

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const EmailConfirmed = () => {
  const [message, setMessage] = useState('Confirming your email...');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // Automatically redirect to login page after 3 seconds
  useEffect(() => {
    const confirmEmail = async () => {
      // Extract the token from the URL query parameters
      const params = new URLSearchParams(location.search);
      const token = params.get('token');

      if (!token) {
        setError('Invalid confirmation link.');
        setMessage('');
        return;
      }

      try {
        // Send a request to the backend to confirm the email
        await axios.post(`${process.env.REACT_APP_API_URL}/confirm-email`, { token });

        setMessage('Your email has been confirmed successfully!');
        setError('');

        // Redirect to login page after a delay
        setTimeout(() => {
          navigate('/login');
        }, 5000);
      } catch (err) {
        setError('Email confirmation failed. The link may have expired.');
        setMessage('');
      }
    };

    confirmEmail();
  }, [location.search, navigate]);


  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900">
      <div className="w-full max-w-md p-8 space-y-6 bg-gray-800 rounded-lg shadow-lg text-center">
        {message && <h2 className="text-3xl font-bold text-white">{message}</h2>}
        {error && <h2 className="text-3xl font-bold text-red-500">{error}</h2>}
        <p className="text-lg text-gray-300">
          {message ? 'You will be redirected to the login page shortly...' : ''}
        </p>
      </div>
    </div>
  );
};

export default EmailConfirmed;
