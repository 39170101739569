import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from './Button'; // Import the Button component
import Input from './Input'; // Import the Input component
import { useNavigate } from 'react-router-dom';

const TwoFASetup = () => {
const [qrCodeDataURL, setQrCodeDataURL] = useState(''); // State to store the QR code URL
  const [twoFaCode, setTwoFaCode] = useState(''); // State to store the 2FA code input
  const [backupCode, setBackupCode] = useState(''); // For Backup Code input
  const [message, setMessage] = useState('');
  const [isGeneratingBackupCode, setIsGeneratingBackupCode] = useState(false);
  const [isSetupComplete, setIsSetupComplete] = useState(false); // Track if setup is complete
  const navigate = useNavigate();

  // Clear error message when user starts typing the 2FA code
  useEffect(() => {
    if (twoFaCode) {
      setMessage('');
    }
  }, [twoFaCode]);

    // Clear error message when user starts typing the backup code
    useEffect(() => {
      if (backupCode) {
        setMessage('');
      }
    }, [backupCode]);

  useEffect(() => {
    // Fetch the QR code for 2FA setup when the component mounts
    const fetchQRCode = async () => {
      const token = localStorage.getItem('token'); // Get the token from local storage

      if (!token) {
        setMessage('No token found, please log in again.');
        console.error("No token found in localStorage.");
        return;
      }

      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/2fa/generate`, {}, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });

        if (res.data.qrCodeDataURL) {
          setQrCodeDataURL(res.data.qrCodeDataURL);
        } else {
          setMessage('Failed to generate QR code for 2FA.');
          console.error("QR Code generation failed:", res.data);
        }
      } catch (err) {
        setMessage('Failed to fetch QR code for 2FA.');
        console.error("Error fetching QR Code:", err.response?.data || err.message);
      }
    };

    fetchQRCode();
  }, []);

  const handleTwoFaVerification = async (e) => {
    e.preventDefault();
    setIsGeneratingBackupCode(true); // Start loading
  
    const token = localStorage.getItem('token'); // Get the token from local storage
  
    if (!token) {
      setMessage('No token found, please log in again.');
      setIsGeneratingBackupCode(false);
      return;
    }
  
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/2fa/verify`, 
        { twoFaCode },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        }
      );
  
      if (res.data.success) {
        // Set the twoFaSetupCompleted flag to true in the backend
        await axios.put(`${process.env.REACT_APP_API_URL}/user/complete-2fa`, {}, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });

        // Generate and fetch a backup code
        const backupRes = await axios.post(`${process.env.REACT_APP_API_URL}/generate-backup-code`, {}, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });

        if (backupRes.data.backupCode) {
          setBackupCode(backupRes.data.backupCode); // Display the generated backup code
        }

        // Indicate that the 2FA setup is complete
        setIsSetupComplete(true);
        setMessage('2FA setup successful! Please login');


      } else {
        setMessage('2FA verification failed. Please try again.');
      }
    } catch (err) {
      setMessage('2FA verification failed.');
      console.error(err.response?.data || err.message);
    } finally {
      setIsGeneratingBackupCode(false); // Stop loading
    }
  };

  // Handle manual login click
  const handleLoginClick = () => {
    navigate('/login'); // Redirect to login page when the user clicks the login button
  };
  

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900">
      <div className="w-full max-w-md p-8 space-y-6 bg-gray-800 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold text-center text-white">2FA Setup</h2>
        <div className="mt-6 text-center">
        <p className="text-white">2FA is required in order to use the website.</p>
        {!isSetupComplete && (
          <>
          <p className="text-white">Scan the QR code below with your 2FA app:</p>
          {qrCodeDataURL ? (
            <img src={qrCodeDataURL} alt="QR Code" className="mx-auto mt-4" />
          ) : (
            <p className="text-gray-400 mt-2">Loading QR Code...</p>
          )}
          <p className="text-gray-400 mt-2">After scanning, enter the 2FA code below:</p>

          <form onSubmit={handleTwoFaVerification} className="mt-4">
            <Input
              type="text"
              value={twoFaCode}
              onChange={(e) => setTwoFaCode(e.target.value)}
              placeholder="Enter 2FA Code"
              className="w-full px-3 py-2 mt-1 text-gray-900 bg-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <Button
              type="submit"
              className="w-full px-4 py-2 mt-4 font-bold text-white bg-green-600 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              disabled={isGeneratingBackupCode} // Disable the button while generating
            >
              {isGeneratingBackupCode ? 'Verifying...' : 'Verify 2FA'}
            </Button>
          </form>
          </>
          )}

          {/* Display the generated backup code after successful 2FA setup */}
          {isSetupComplete && backupCode && (
            <>
            <div className="mt-6 p-4 bg-gray-700 text-white rounded-md">
              <p>Your backup code (store this in a safe place):</p>
              <p className="font-bold text-lg mt-2">{backupCode}</p>
              <p className="text-sm mt-2">Use this code if you lose access to your 2FA device.</p>
            </div>

              {/* Login button to manually redirect when user is ready */}
              <Button
                type="button"
                onClick={handleLoginClick}
                className="w-full px-4 py-2 mt-6 font-bold text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Login
              </Button>
            </>
          )}          


          {message && <p className="mt-4 text-center text-red-500">{message}</p>}
        </div>
      </div>
    </div>
  );
};

export default TwoFASetup;
